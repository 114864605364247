//interfaces
import { Utm } from '@components/lib/interfaces/model/models/Utm';
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update utm state.
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<string>} action - The payload
 */
const setUtmReducer = (state: Draft<State>, action: PayloadAction<Utm>) => {
    state.utm = action.payload;
};

export default setUtmReducer;
